html, body {
    overflow-x: hidden;
}
/* Constant */
.bg-black {
    background: black;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.align-center {
    text-align: center;
}

.padding-0-5 {
    padding: 0 5px;
}

.padding-0-50 {
    padding: 0 50px;
}

.padding-20-0 {
    padding: 20px 0px;
}

.pr-5 {
    padding-right: 5px !important;
}

.pr-20 {
    padding-right: 20px !important;
}

.pr-25 {
    padding-right: 25px;
}

.pl-5 {
    padding-left: 5px !important;
}

.pl-10 {
    padding-left: 10px !important;
}

.pl-20 {
    padding-left: 20px !important;
}

.pl-30 {
    padding-left: 30px !important;
}

.pl-40 {
    padding-left: 40px;
}

.pl-50 {
    padding-left: 50px;
}

.pt-none {
    padding-top: 0px !important;
}

.margin-auto {
    margin: auto;
}

.mr-20 {
    margin-right: 20px;
}

.mr-10 {
    margin-right: 10px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-50 {
    margin-top: 50px;
}

.mb-none {
    margin-bottom: 0;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-50 {
    margin-bottom: 50px !important;
}

.mobile-view {
    display: none;
}

.hidden {
    display: none;
}

.row {
    margin: 0;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.position-relative {
    position: relative;
}

.font-12 {
    font-size: 12px !important;
}

.underline {
    text-decoration: underline;
}

.warning-color {
    color: #ffae42;
}

.danger-color {
    color: red;
}

.blue-color {
    color: blue;
}

.black-color {
    color: black;
}

.success-color {
    color: green;
}

.width-100p {
    width: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

.table {
    display: table;
    margin-bottom: 0;
    height: 100%;
}

.table-row {
    display: table-row;
}

.table-cell {
    display: table-cell;
}

.section-space {
    margin-top: 70px !important;
}

.btn-style-2 {
    color: black;
    background: transparent;
    border: none;
    font-size: 14px;
    font-weight: bold;
}

@media (max-width: 767px) {
    .mobile-view {
        display: block;
    }

    .expand-view {
        display: none;
    }
}

/* body */
body {
    font-family: 'Roboto', sans-serif;
}

img {
    width: 100%;
}

/* Header */

#header {
    max-width: none;
    display: flow-root;
    padding: 0 1rem;
}

#header .navbar-nav li.nav-item {
    float: left;
}

#header .navbar-nav .nav-item a {
    color: #FFF;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    padding: 10px 10px;
    display: block;
} 

#header .navbar-nav .nav-item:hover a {
    color: #3694F4;
    opacity: 1;
}

.dropdown-menu {
    padding: 5px 0;
    border-radius: 0;
    margin: 0;
    margin-left: -1px;
    width: 230px;
    z-index: 10000;
}

#header .dropdown-menu .dropdown-item {
    border-bottom: 1px solid #eee;
    color: grey;
    display: block;
    letter-spacing: 0.5px;
}

#header .dropdown-menu .dropdown-item:hover {
    color: darkgrey;
}

#header .dropdown-menu a {
    padding: 3px 10px !important;
    font-weight: normal !important;
}

.navbar-toggler {
    background: #f7f7f7;
}

#header ul.navbar-nav {
    width: 770px;
    display: block;
    margin: auto;
}

.navbar-brand {
    margin-right: 0;
    width: 100%;
    text-align: center;
    background-image: url(../images/header_bg1.webp), url(../imagesSlow/header_bg1.png);
}

.navbar-brand > a {
    position: relative;
}

.navbar-brand img {
    width: 270px;
}

.navbar-brand span {
    position: absolute;
    color: white;
    font-size: 14px;
    bottom: -17px;
    right: -25px;
    font-weight: bold;
}

.navbar-light .navbar-toggler-icon {
    background-image: url(../imagesSlow/more_vert_icon.png);
    background-size: auto;
}

button.navbar-toggler {
    background: transparent;
    
}

#header .navbar-nav .login > a, 
#header .navbar-nav .cart > a {
    font-size: 11px;
    line-height: 20px;
    font-weight: bold;
}

#header .navbar-nav .search i {
    font-size: 16px;
    line-height: 19px;
}

@media (max-width: 991px) {
    .collapse:not(.show) {
        display: none !important;
    }

    .navbar-expand-md .navbar-toggler {
        display: block;
        float: right;
        position: absolute;
        top: -56px;
        right: 10px;
    }

    .navbar-expand-md .navbar-collapse {
        /* margin-left: 50px; */
        overflow: hidden;
    }

    .navbar-expand-md .navbar-collapse ul li a {
        text-align: center;
    }

    #header ul.navbar-nav li {
        width: 100%;
    }

    #header ul.navbar-nav {
        /* margin-top: 50px; */
    }

    .bg-black {
        background-image: url(../images/header_bg_2.webp), url(../imagesSlow/header_bg_2.png);
    }

    #header .navbar-nav .nav-item a {
        color: #000;
    }
    
}

@media (min-width: 768px) {
    .collapsing {
        transition: height .35s ease;
    }

    .navbar-expand-md .show.navbar-collapse {
        display: block !important;
        flex-basis: auto;
    }

    .navbar-expand-md .navbar-collapse {
        display: none !important;
        flex-basis: auto;
    }
}

@media (min-width: 992px) {
    .navbar-expand-md .navbar-collapse {
        display: flex!important;
        flex-basis: auto;
    }

    .navbar-expand-md .navbar-collapse {
        display: block !important;
        flex-basis: auto;
    }
}

@media (max-width: 991px) {
    .navbar-nav .dropdown-menu {
        margin: auto;
        font-size: 12px;
        text-align: center;
        position: unset !important;
    }
}

@media (max-width: 770px) {
    #header ul.navbar-nav {
        width: auto;
    }
}

/* Main Video */
main {
    /* margin-top: 57px; */
}

main.banner img {
    width: 640px;
    height: 244px;
   
}
.garages-gallery .offer-item{
    width: 268px;
    height: 208px;
}

.jumbotron > div, .jumbotron iframe {
    width: 100% !important;
    height: calc(100vw * 9 / 16) !important;
}

.jumbotron {
    padding: 0;
    margin-top: -70px;
}

.banner-title {
    text-align: center;
    position: absolute;
    top: calc(100vw * 55/288 - 30px);
    width: 100%;
    color: white;
    z-index: 1000;
}

.banner-title .title {
    z-index: 7;
    color: rgb(0, 0, 0);
    white-space: nowrap;
    visibility: inherit;
    line-height: 70px;
    border-width: 0px;
    margin: 0px;
    padding: 10px 0px 0px;
    letter-spacing: 0px;
    font-weight: 800;
    font-size: 50px;
    min-height: 0px;
    min-width: 0px;
    max-height: none;
    max-width: none;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform-origin: 50% 50% 0px;
    transition: none 0s ease 0s;
  

}

.banner-title .welcome {
    z-index: 8;
    white-space: nowrap;
    color: rgb(5, 4, 4);
    visibility: inherit;
    transition: none 0s ease 0s;
    line-height: 20px;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    font-weight: 500;
    font-size: 25px;
    min-height: 0px;
    min-width: 0px;
    max-height: none;
    max-width: none;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform-origin: 50% 50% 0px;
   
}

.banner-title .shopping {
    z-index: 9;
    white-space: nowrap;
    color: black;
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.5);
    border-width: 1px;
    outline: none;
    box-shadow: none;
    box-sizing: border-box;
    cursor: pointer;
    visibility: inherit;
    transition: none 0s ease 0s;
    line-height: 14px;
    margin: 0px;
    padding: 10px 30px;
    letter-spacing: 1px;
    font-weight: 500;
    font-size: 14px;
    min-height: 0px;
    min-width: 0px;
    max-height: none;
    max-width: none;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform-origin: 50% 50% 0px;
    margin-top: 40px;
}

.banner-title .shopping span {
    background: #3694F4;
}

.banner-title .shopping span {
    padding: 7px 30px;
    border: solid 1px;
}

@media (max-width: 991px) {
    .jumbotron {
        padding: 0;
        margin-top: 0px;
    }

    .banner {
        position: relative;
    }
}

@media (max-width: 991px) {
    .banner-title {
        top: calc(100vw * 292 / 1534 - 100px);
    }
}

@media (max-width: 767px) {
    main.banner img {
        min-height: 137px;
        min-width: 360px;
    }

    .banner-title .title {
        font-size: 30px;
        line-height: 40px;
    }

    .banner-title {
        top: calc(100vw * 292 / 1534 - 55px);
    }

    .banner-title .shopping {
        font-size: 10px;
    }
}


/* Carousel */
.gallery-section {
}

.black-section img {
    width: 100%;
    height: 50px;
    position: relative;
}

ul.alice-carousel__stage li.alice-carousel__stage-item .gallery-item {
    text-align: center;
    padding: 0 10px;
}

ul.alice-carousel__stage li.alice-carousel__stage-item .gallery-item img {
    width: 100%;
}

ul.alice-carousel__stage li.alice-carousel__stage-item .gallery-item h4 {
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    color: #666;
    line-height: 22px;
    font-weight: 700;
    margin-top: 10px;
}

.heading-icon {
    font-size: 16px;
    color: white;
}

.garages-gallery div.row, .febrication-gallery div.row {
    padding: 15px 0;
}

.fa-angle-left, .fa-angle-right {
    background-color: #d1d1d1;
    font-size: 20px;
    height: 25px;
    width: 25px;
    line-height: 25px;
    color: white;
}

.arrow-div button {
    background: transparent;
    border: 0;
    padding: 0;
}

.arrow-div i:hover {
    background-color: #cc181e;
}

.section-title {
    font-size: 20px;
    font-weight: 700;
    color: #333333;
    margin-bottom: 30px;
}

.gallery-item a:before, .gallery-item a:after {
    content: "";
    clear: both;
    display: block;
}

.gallery-item a {
    position: relative;
}

.carports-section .gallery-item a img {
    width: 100%;
}

.gallery-item a:before {
    color: #fff;
    content: "\f01d";
    font-family: "FontAwesome";
    font-size: 60px;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(180deg);
    transform: translateY(-50%) rotate(180deg);
    -webkit-transition: all .3s linear 0s;
    transition: all .3s linear 0s;
    z-index: 11;
}

.gallery-section {
    padding: 80px 0;
    background: black;
}

/* Carports Section */

.carports-section, .hiring-section {
    padding: 70px 0;
}

.carports-section .section-title i {
    padding: 7px 10px;
    background: black;
}

.carports-section div.title-div {
    padding: 15px 0;
}

.see-all-div a {
    color: #cc181e;
    padding-left: 10px;
    line-height: 24px;
    position: relative;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 400;
}

.gallery-item h4 {
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    color: #666;
    line-height: 22px;
    font-weight: 700;
    margin-top: 10px;
}

.carports-section .gallery-item {
    margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
    .hiring-section img {
        width: 100%;
    }
}



/* Footer */ 

#footer {
    background: #000000;
    color: #666666;
}

.call-to-action-area {
    padding: 40px 0;
    color: #fff;
}

.themeix-purchase-btn-3 {
    padding: 10px 20px;
    background-color: rgb(19, 18, 18);
    color: #000000;
    -webkit-transition: all .4s linear 0s;
    transition: all .4s linear 0s;
    text-transform: uppercase;
    font-size: 14px;
}

.themeix-purchase-btn-3:hover {
    text-decoration: none;
    background-color: #3694f4;
    color: rgb(5, 5, 5);
}

.widget-block {
    width: 100%;
    float: left;
    margin-bottom: 30px;
}

.footer-heading-wrap {
    margin-bottom: 10px;
}

.footer-heading {
    display: inline-block;
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 700;
    margin-top: 0;
}

.single-footer .social-links {
    margin-top: 20px;
}

#footer ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

#footer .heading-icon {
    color: #fff;
    display: inline-block;
    height: 35px;
    line-height: 35px;
    margin-right: 10px;
    text-align: center;
    width: 35px;
}

.widget-block .social-links ul li {
    border: none;
    width: inherit;
    float: left;
}

.social-links ul li a {
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: #cfcbcb;
    margin-right: 5px;
    border-radius: 50%;
    font-size: 18px;
    color: #fff;
    -webkit-transition: all .4s linear 0s;
    transition: all .4s linear 0s;
}

.widget-block .social-links a:hover {
    background-color: #676263;
    color: #fff;
}

.single-footer-text p {
    line-height: 24px;
    font-size: 14px;
}

.widget-block.footer-widget ul li {
    border: none;
    line-height: 24px;
    font-size: 14px;
}

.widget-block.footer-widget ul li a {
    color: #666666; 
}

.textwidget p {
    line-height: 24px;
    font-size: 14px;
}

#footer .center {
    padding: 50px 0;
}

#footer {
    padding-bottom: 60px;
}

#footer .copyright-text p {
    text-align: center;
    font-size: 14px;
}

@media only screen and (max-width: 767px) {
    .action-content {
        text-align: center;
    }

    .purchase-link.text-right {
        text-align: center !important;
    }

    .call-to-action-area {
        margin-bottom: 25px;
    }
}

/* Phone Number Section */
.phone-number {
    margin-top: 30px;
}

.phone-number p {
    font-size: 60px;
    text-align: center;
    color: #000000;
    margin-bottom: 0;
}

.phone-number .chat {
    max-width: 500px;
    text-align: right;
    margin: auto;
    font-size: 12px;
    position: relative;
}

.phone-number .chat i {
    position: absolute;
    right: 110px;
}

@media (max-width: 767px) {
    .phone-number p {
        font-size: 40px;
    }

    .phone-number .chat {
        text-align: center;
        padding-left: 30px;
    }

    .phone-number .chat i {
        right: calc(50% + 45px);
    }
}

/* What We Offer Section */
.offer-section {
    margin-top: 50px;
}

.section-title h4 {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 0;
}

.section-title h4 span {
    color: #3694F4;
}

.section-title p {
    font-size: 14px;
    text-align: center;
    opacity: 0.54;
    font-weight: normal;
}

.alice-carousel .offer-item {
    padding: 10px;
}

.alice-carousel .offer-item img {
    width: 100%;
}

.alice-carousel .offer-item .content {
    padding: 0 7px;
}

.alice-carousel .offer-item .content h4 {
    font-size: 18px;
}

.alice-carousel .offer-item .content p {
    font-size: 12px;
    opacity: 0.54;
    line-height: 18px;
}

.alice-carousel .offer-item .content .read-more a {
    font-size: 12px;
    line-height: 24px;
}

.alice-carousel .offer-item .content .read-more button {
    font-size: 12px;
    color: #0F4505;
    float: right;
    background: #E5EAE9;
    border: 0;
    padding: 5px 10px;
}

.alice-carousel .offer-item .content .read-more button span {
    font-size: 10px;
}

.alice-carousel .offer-item .water-mark {
    position: absolute;
    left: calc(50% - 74px);
    bottom: 30px;
    font-size: 12px;
    color: white;
}

.garages-gallery {
    position: relative;
}

.garages-gallery .left-arrow {
    position: absolute;
    left: -30px;
    background: unset;
    border: 0;
    top: calc(50% - 13px);
}

.garages-gallery .right-arrow {
    position: absolute;
    right: -30px;
    background: unset;
    border: 0;
    top: calc(50% - 13px);
}

.gallery-container {
    background: url(../images/offer-carousel-bg.webp);
    padding-bottom: 10px;
    background-size: contain;
}

.gallery-container .container {
    padding: 0 30px;
}

.more-btn {
    background: transparent;
    border: 0;
    line-height: 5px;
    font-size: 12px;
    padding-bottom: 10px;
}

.more-btn-div {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.more-btn-div i {
    width: 100%;
    font-size: 40px;
    line-height: 10px;
}

@media (max-width: 767px) {
    .alice-carousel .offer-item .content {
        padding: 0 12px;
    }

    .alice-carousel .offer-item {
        padding: 0px 30px 30px;
        margin-top: 30px;
        background: url(../imagesSlow/offer-carousel-bg.png);
        background-size: contain;
    }
}

/* Our Videos */
.videos-section {
    margin-top: 50px;
}

.videos-section .item .video-img {
     width: calc(100%);
     /*height: 86px; */
}

.videos-section .item .content {
    padding: 0 7px;
    font-size: 14px;
}

.videos-section .item .content h6 {
    margin-bottom: 0;
}

.videos-section .item .content p img {
    margin-right: 10px;
    margin-bottom: 2px;
    width: 28px;
    height: 16px;
}

.videos-section .item .video {
    position: relative;
}

.videos-section .item .video-icon-1 {
    position: absolute;
    top: 10px;
    right: 13px;
    width: 20px;
    /* display: none; */
}

.videos-section .item .video-icon-2 {
    width: 40px;
    position: absolute;
    bottom: 15px;
    left: 12px;
    /* display: none; */
}

.videos-section .arrow-section {
    width: 100%;
    text-align: right;
    padding: 0 7px;
}

.videos-section .arrow-section button {
    background: none;
    border: 0;
    padding: 0;
    height: 24px;
    background: #E5EAE9;
}

.videos-section .arrow-section button i {
    line-height: 24px;
    color: #3694F4;
    font-size: 20px;
}

@media (max-width: 767px) {
    .videos-section .item .video-icon-2 {
        bottom: 25px;
        left: 20px;
        width: 45px;
    }

    .videos-section .item .video-icon-1 {
        top: 15px;
        right: 20px;
        width: 25px;
    }
}

/* Our Services */
.services-section {
    margin-top: 50px;
}

.services-section .item {
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
    padding: 15px 15px 15px 30px;
    height: 100%;
}

.services-section .item h4 {
    font-family: "Roboto";
}

.services-section .item p {
    font-size: 12px;
    opacity: 0.54;
}

.services-section .item i {
    position: absolute;
    font-size: 36px;
    color: #3694F4;
    background: white;
    top: calc(50% - 18px);
    left: 0px;
}

.services-section .col-md-4 {
    padding: 0 20px;
    margin-bottom: 30px;
}
 
/* State Section */
.state-section {
    margin-top: 50px;
}

.state-section .left .state img {
    width: 100%;
    padding: 50px;
}

.state-section .left .content {
    text-align: center;
}

.state-section .left .content h2 {
    color: #3694F4;
    margin-bottom: 20px;
}

.state-section .left .content span {
    margin-right: 20px;
}

.state-section .left .content select {
    border: none;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    padding: 5px 10px;
    font-size: 14px;
    margin-right: 5px;
    margin-bottom: 5px;
}

.state-section .right h4 img {
    width: 40px;
    margin-right: 15px; 
}

.state-section .right h4 {
    border-bottom: solid 1px;
    padding-bottom: 7px;
    margin-bottom: 20px;
}

.state-section .right p {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
    display: table-row;
}

.state-section .right p span:first-child {
    padding-right: 20px;
    display: table-cell;
}

@media (max-width: 767px) {
    .state-section .right {
        margin-top: 30px;
    }
}

/* Why Choose us */

.why-choose-section {
    margin-top: 50px;
}

.why-choose-section .row {
    margin: 0;
}

.why-choose-section .left {
    padding: 0;
}

.why-choose-section .left img {
    width: 100%;
    height: 350px;
}

.why-choose-section .right {
    background: #222222;
    padding: 30px 50px;
}

.why-choose-section .right h6 {
    color: #E2E2E2;
    font-size: 16px;
}

.why-choose-section .right h6 span {
    color: #3694F4;
    font-style: italic;
    font-weight: bold;
}

.why-choose-section .right p {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 12px;
}

.why-choose-section .right p a {
    display: inline-block;
}

.why-choose-section .right .section-title h4 {
    color: white;
    text-align: left;
    padding-left: 15px;
}

@media (max-width: 767px) {
    .why-choose-section .left img {
        height: unset;
    }

    .why-choose-section .right .col-md-6 {
        width: 50%;
        float: left;
    }
}

/* Latest Projects */

.latest-section {
    margin-top: 50px;
}

.latest-section .row .col-md-4 {
    margin-bottom: 20px;
}

/* Brands Section */

.brands-section {
    margin-top: 50px;
    background: #F5F5F5;
}

.brands-section .container {
    position: relative;
}

.brands-section .container button {
    background: transparent;
    border: 0;
}

.brands-section .container button.left-arrow {
    position: absolute;
    top: calc(50% - 18px);
    left: -25px;
}

.brands-section .container button.right-arrow {
    position: absolute;
    top: calc(50% - 18px);
    right: -25px;
}

.brands-section p {
    padding-bottom: 15px;
    margin-top: -15px;
    margin-bottom: 0;
    padding-left: 15px;
    padding-right: 15px;
}

@media (max-width: 767px) and (max-height: 0px) {
    .brands-section .container button.left-arrow {
        left: 0px;
        z-index: 1000;
    }
    
    .brands-section .container button.right-arrow {
        right: 0px;
        z-index: 1000;
    }   
}

/* Footer */

#footer {
    background: #222222;
}

#footer .action-content h2 {
    font-size: 30px;
}

#footer .action-content p {
    font-size: 14px;
}

#footer .themeix-purchase-btn-3 {
    position: absolute;
    max-width: 157px;
    width: 100%;
    right: 0;
    top: 10px;
    padding: 5px;
    text-align: center;
    background: #3694F4;
    color: rgb(8, 8, 8);
    font-weight: bold;
    font-size: 14px;
}

#footer .center {
    padding: 0 0 50px 0;
}

.footer-heading-wrap .heading-icon i {
    font-size: 14px;
}

.footer-heading {
    font-size: 18px;
    color: white;
}

.single-footer-text p {
    line-height: 24px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);
}

.widget-block.footer-widget ul li a, #text-3 .textwidget p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 12px;
}

.social-links ul li a {
    width: 30px;
    height: 30px;
    background-color: #000;
    color: #fff;
    position: relative;
    display: table;
    margin-right: 10px;
    margin-bottom: 10px;
}

.social-links ul li a i {
    top: 10px;
    right: 12px;
    vertical-align: middle;
    display: table-cell;
}

#footer .bottom {
    background: #000;
    color: white;
    font-size: 12px;
    padding: 20px;
    border-top: solid 2px #707070;
}

#footer .bottom p {
    margin-bottom: 0;
}

#footer {
    padding-bottom: 0px;
}

@media (max-width: 991px) {
    .why-choose-section .right {
        padding: 15px 15px;
    }

    .footer-heading {
        font-size: 14px;
    }
}

@media (max-width: 767px) {
    #footer .themeix-purchase-btn-3 {
        right: calc(50% - 78.5px);
    }
}

/********************************************* 
 *            Agricultural Page
 *********************************************/

/* Banner */

#agricultural .banner {
    background: url(../images/Agricultural/Image24.webp),url(../imagesSlow/Agricultural/Image24.png) ;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: white;
    padding: 30px;
}

#agricultural .row {
    margin: 0;
}

#agricultural .banner .banner-mark {
    width: 100px;
    min-height: unset;
}

#agricultural .banner .left h2 {
    text-align: center;
    font-weight: bold;
    max-width: 355px;
    margin-bottom: 30px;
}

#agricultural .banner .left p {
    font-size: 14px;
}

#agricultural .banner .left .bottom {
    margin-top: 40px;
    padding: 0 15px;
}

#agricultural .banner .left .bottom p {
    width: calc(100% - 120px);
    max-width: 310px;
    float: left;
}

#agricultural .banner .right form input, 
#agricultural .banner .right form select,
.modal-body form input, 
.modal-body form select
 {
    width: 100%;
    margin-bottom: 9px;
    font-size: 12px;
    padding: 8px 15px;
    border-radius: 2px;
    border: unset;
    /* -webkit-appearance:none; */
}

#agricultural .banner .right form,
.modal-body form {
    background: #4a4949;
    padding: 20px 0 5px;
    max-width: 300px;
    color: white;
    margin: auto;
}

#agricultural .banner .right form .call-div p,
.modal-body form .call-div p {
    font-size: 14px;
    margin-bottom: 5px;
}

#agricultural .banner .right form .submit-btn,
.modal-body form .submit-btn {
    background: #fd8223;
    color: white;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
}

.modal-body form .row {
    margin: 0;
}

.modal-content {
    background-color: unset;
    border: 0px solid rgba(0,0,0,.2);
}

@media (max-width: 767px) {
    #agricultural .banner {
        padding: 10px 0;
    }

    #agricultural .banner .left h2 {
        font-size: 24px;
        margin-bottom: 5px;
        margin-left: auto;
        margin-right: auto;
    }

    #agricultural .banner .left p {
        font-size: 12px;
        margin-bottom: 5px;
    }

    #agricultural .banner .left .bottom {
        margin-top: 20px;
        padding: 0 15px;
    }

    #agricultural .banner .left {
        text-align: center;
    }

    #agricultural .banner .right {
        padding: 30px 10px;
    }

    #agricultural .banner .left .bottom p {
        float: unset;
        margin: auto;
        margin-bottom: 10px;
    }

    .pr-5 {
        padding-right: 15px !important;
    }

    .pl-5 {
        padding-left: 15px !important;
    }
}

/* Style Section 1 */

.style-section-1 {
    margin-top: 50px;
}

.style-section-1 .content {
    margin-top: -20px;
    text-align: center;
}

.style-section-1 .content p, .style-section-2 .content p {
    font-size: 12px;
    text-align: center;
}

.style-section-1 .content h4, .style-section-2 .content h4 {
    font-size: 20px;
}

/* Style Section 2 */

.style-section-2 {
    margin-top: 30px;
}

.style-section-2 .content {
    position: absolute;
    top: calc(50% - 33px);
    left: calc(50% - 113px);
}

.style-section-2 .right > .col-md-6 {
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
}

.style-section-2 .right p {
    font-size: 12px;
    position: absolute;
    top: calc(50% - 15px);
    left: calc(50% - 36px);
    line-height: 14px;
    text-align: center;
}

@media (max-width: 767px) {
    .style-section-2 .right p {
        left: calc(50% - 45px);
    }

    .style-section-2 .content {
        top: calc(50% - 20px);
    }
}

/* Investment Section */

.investment-section {
    border-top: solid #007bff;
    padding: 50px;
    text-align: center;
    background: url(../images/Agricultural/barnBlock.webp), url(../imagesSlow/Agricultural/barnBlock.png);
    color: white;
    background-size: cover;
    background-repeat: no-repeat;
}

.investment-section h4 {
    margin-bottom: 45px;
}

.investment-section p {
    max-width: 770px;
    margin: auto;
    font-size: 14px;
    margin-bottom: 30px;
}

.investment-section p a {
    display: inline-block;
    color: #007bff !important;
    cursor: pointer;    
}

@media (max-width: 767px) {
    .investment-section {
        border-top: solid #007bff;
        padding: 20px 0;
    }

    .investment-section h4 {
        margin-bottom: 20px;
    }

    .investment-section p {
        margin-bottom: 10px;
    }
}

/* Delivered Section */

.delivered-section {
    margin-top: 50px;
}

.delivered-section h2 {
    font-weight: bold;
    font-size: 28px;
    text-align: center;
    margin-bottom: 30px;
}

.delivered-section h4 {
    font-size: 20px;
}

.delivered-section .col-md-4 img {
    max-width: 200px;
    margin-top: 20px;
}

.build-online-btn {
    background: #009688;
    border: none;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 500;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
}

.bottom-description-section {
    background: url(../images/Agricultural/Image39.webp), url(../imagesSlow/Agricultural/Image39.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px;
    background-position: center;
    margin-top: 50px;
}

/********************************************* 
 *               Carports Page
 *********************************************/

#carports {
    padding-bottom: 50px;
} 

#carports .product-section {
    margin-top: 40px;
}

#carports .slider-item {
    padding: 0 10px;
}

#carports .slider-section {
    margin-top: 40px;
}

#carports .product-section .col-md-6 {
    padding: 0 20px;
    margin-bottom: 40px;
}

#carports .product-section .item {
    padding: 15px 15px 30px;
    box-shadow: 3px 5px 20px rgba(0, 0, 0, 0.25);
    height: 100%;
}

#carports .product-section .item .title-div {
    overflow: hidden;
    max-width: 300px;
    margin: 20px auto;
    background: #CDE3F8;
    padding: 7px 20px;
    font-size: 16px;
}

#carports .product-section .item .description {
    font-size: 12px;
    padding: 7px 10px;
    text-align: justify;
    color: rgba(0, 0, 0, 0.54);
    margin-bottom: 10px;
}

#carports .product-section .item .button-div {
    position: absolute;
    bottom: 15px;
    right: 40px;
}

#carports .product-section .item button {
    color: #3694F4;
    background: none;
    border: 0;
    font-size: 12px;
    margin-left: 10px;
}

#carports .product-section .item .price {
    color: #3694F4;
}

#carports .slider-section .slider-item .price {
    position: absolute;
    width: 130px;
    height: 130px;
    font-size: 20px;
    right: 70px;
    bottom: 42px;
    color: #3694F4;
    background: white;
    border: solid 9px #3694F4;
    border-radius: 50%;
    font-weight: bold;
    text-align: center;
    line-height: 28px;
    padding: 10px;
}

.carports .state-section {
    margin-top: 70px;
    margin-bottom: 50px;
}

.carports .state-section .state {
    width: 160px;
    float: left;
    padding: 5px 20px;
}

.carports .state-section .right {
    max-width: 300px;
    margin-bottom: 20px;
}

.carports .state-section .left .content {
    text-align: center;
    float: left;
}

.carports .state-section .left > div {
    overflow: hidden;
    width: fit-content;
    margin: auto;
    text-align: center;
}

.carports .state-section .right h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
}

.carports .state-section .right h4 img {
    width: 30px;
    margin-right: 10px;
}

.carports .state-section .left .content h2 {
    color: #3694F4;
    margin-bottom: 10px;
    font-size: 24px;
    text-align: center;
}

.carports .state-section .left .content span {
    margin-right: 20px;
    font-size: 14px;
}

.carports .state-section .left .content select {
    font-size: 12px;
}

@media (max-width: 767px) {
    #carports .slider-section .slider-item .price {
        width: 100px;
        height: 100px;
        font-size: 12px;
        line-height: 20px;
        right: 30px;
        bottom: 15px;
    }

    .carports .state-section .left > div img,
    .carports .state-section .left > div .content {
        float: unset;
    }
}

/**********************************
 *        Custom Check Box
 **********************************/
 .styled-checkbox {
	position: absolute;
	opacity: 0;
}

.styled-checkbox + label {
	position: relative;
	cursor: pointer;
	padding: 0;
}

/* Box. */
.styled-checkbox + label:before {
	content: '';
	margin-right: 10px;
	display: inline-block;
	vertical-align: text-top;
	width: 20px;
	height: 20px;
	background: white;
}

/* Box hover */
.styled-checkbox:hover + label:before {
	background: #f35429;
}

/* Box focus */
.styled-checkbox:focus + label:before {
	box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

/* Box checked */
.styled-checkbox:checked + label:before {
	background: #f35429;
}

/* Disabled state label. */
.styled-checkbox:disabled + label {
	color: #b8b8b8;
	cursor: auto;
}

/* Disabled box. */
.styled-checkbox:disabled + label:before {
	box-shadow: none;
	background: #ddd;
}

/* Checkmark. Could be replaced with an image */
.styled-checkbox:checked + label:after {
	content: '';
	position: absolute;
	left: 5px;
	top: 11px;
	background: white;
	width: 2px;
	height: 2px;
	box-shadow: 
    	2px 0 0 white,
    	4px 0 0 white,
    	4px -2px 0 white,
    	4px -4px 0 white,
    	4px -6px 0 white,
    	4px -8px 0 white;
	transform: rotate(45deg);
}

/**********************************
 *        Custom Check Box
 **********************************/

/* Agree Alert */
.agree-alert {
    background: white;
    padding: 30px 15px;
    border-radius: 2px;
}

/**********************************
 *    Finance And Weekly Banner
 **********************************/
.finance, .weekly {
    border: solid 2px #707070;
    text-align: center;
    margin-top: 20px;
}

.finance .top {
    font-size: 80px;
    font-weight: bold;
    line-height: 80px;
    color: #E56A57;
}

.finance .bottom {
    font-size: 80px;
    font-weight: bold;
    line-height: 80px;
    background: #061F74;
    color: white;
}

.weekly .top {
    font-size: 31px;
    font-weight: bold;
    background: #759870;
    color: black;
}

.weekly .bottom {
    font-size: 31px;
    font-weight: bold;
    background: #2C4018;
    color: white;
}

.weekly .middle {
    overflow: hidden;
    padding: 10px 0;
}

.weekly .middle .left,
.weekly .middle .center,
.weekly .middle .right {
    width: 33.3333%;
    float: left;
}

.weekly .middle .center {
    border: solid 5px #707070;
    position: relative;
}

.weekly .middle .center span {
    border: solid 5px #707070;
    position: absolute;
    border-radius: 50%;
}

.weekly .middle .center span.round-1 {
    top: -4px;
    left: -4px;
}

.weekly .middle .center span.round-2 {
    top: -4px;
    right: -4px;
}

.weekly .middle .center span.round-3 {
    bottom: -4px;
    right: -4px;
}

.weekly .middle .center span.round-4 {
    bottom: -4px;
    left: -4px;
}

.weekly .middle .center span.round-5 {
    left: calc(25% - 10px);
    top: 14px;
}

.weekly .middle .center span.round-6 {
    right: calc(25% - 10px);
    top: 14px;
}

.weekly .middle .left {
    color: #7B6F18;
    font-weight: bold;
    text-align: right;
    padding-right: 15px;
    line-height: 48px;
}

.weekly .middle .right {
    color: #7B6F18;
    font-weight: bold;
    text-align: left;
    padding-left: 15px;
    line-height: 48px;
}

.weekly .middle .center label {
    width: 30px;
    background: #707070;
    border-radius: 50%;
    color: white;
    font-weight: bold;
    font-size: 28px;
    line-height: 28px;
    margin: 5px 0;
}

@media (max-width: 991px) {
    .finance .bottom {
        font-size: 70px;
    }
}

/**********************************
 *              Docs
 **********************************/

 /* Disclosure */
.color-red {
    color: red;
}

.color-blue {
    color: blue;
}

.color-blue-1 {
    color: rgb(0, 0, 0);
}

.txt-color-1 {
    color: rgba(0, 0, 0, 0.5);
}

.font-20 {
    font-size: 20px;
}

.docs h2 {
    font-size: 28px;
    font-weight: bold;
}

.docs p {
    font-size: 14px;
}

.docs hr {
    border-top: 2px solid rgba(0,0,0,.3);
}

.docs h5 {
    font-size: 18px;
    font-weight: bold;
}

/* DisClaimer */
.docs .disclaimer-text {
    font-size: 16px;
    color: rgb(39, 96, 149);;
}

.docs ul {
    font-size: 14px;
}

.docs .damaged-images img {
    max-width: 235px;
    margin: 15px auto;
    border: solid 1px;
    display: inherit;
}

.docs .brands img {
    height: 67px;
    width: auto;
    border: solid 1px;
    margin: 10px;
}

.docs mark {
    background-color: yellow;
}

.docs .coupon-images img {
    width: 70%;
    margin: 15px auto;
    display: inherit;
    max-width: 600px;
}

.docs .state-div select {
    margin-bottom: 9px;
    font-size: 16px;
    font-weight: bold;
    padding: 6px 10px;
    border-radius: 2px;
    width: 230px;
}

/* Comparison */
.docs .skip-to-pricing mark {
    cursor: pointer;
    text-decoration: underline;
    display: inline-block;
}

.docs .brand-cols {
    overflow: hidden;
}

.docs .brand-cols .col {
    flex: 0 0 20%;
    max-width: 20%;
    float: left;
}

.docs .brand-cols .col > div {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    margin-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.docs .brand-cols .col > div button {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    border: unset;
    font-size: 14px;
    font-weight: bold;
}

.docs .brand-cols .col ul {
    font-size: 12px;
    padding: 10px 0;
    list-style: none;
    margin-top: 10px;
    margin-bottom: 0;
}

.docs .brand-cols .col ul li {
    position: relative;
    padding-left: 25px;
    padding-right: 10px;
    margin: 5px 0;
}

.docs .brand-cols .col ul li i {
    font-size: 14px;
    position: absolute;
    top: 0;
    left: 9px;
}

@media (max-width: 991px) {
    .docs .brand-cols .col {
        flex: 0 0 33.3333%;
        max-width: 33.3333%;
        float: left;
    }
}

@media (max-width: 991px) {
    .docs .brand-cols .col {
        flex: 0 0 100%;
        max-width: 100%;
        float: left;
    }
}

/* Color Charts */
#color_charts .row .col-md-4 .brand img {
    border: solid 1px;
    height: 70px;
    width: auto;
}

#color_charts .row .col-md-4 .item {
    max-width: 150px;
    margin: 15px auto;
}

#color_charts .row .col-md-4 .item img {
    border: solid 1px;
    width: 40px;
}

/* FAQ */
#faq .item {
    margin: 5px 0;
}

#faq .item button {
    border-radius: 2px;
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;
    width: 100%;
    text-align: left;
}

#faq .item button:focus {
    box-shadow: unset;
    color: #0080FF;
}

/* Testimonials */
.border-2 {
    border: solid 2px rgba(0,0,0,.3);
}

#testimonials .section img {
    max-width: 400px;
    border: solid 1px;
    margin: 15px;
    width: calc(100% - 30px);
}

#testimonials .section .imgs {
    background: rgb(232, 232, 232);
}

#testimonials .section .reviews p {
    background: rgb(232, 232, 232);
    padding: 20px 30px;
}


/**********************************
 *           Home Page
 **********************************/
.modal .modal-content > .modal-body > div {
    max-width: 100%;
}

/* Special Offer */
.special-offer img {
    height: 100%;
    border: solid 2px;
}

.special-offer h5 {
    margin-top: 10px;
    font-size: 14px;
    font-weight: bold;
}

.special-offer .col-md-4 {
    margin-bottom: 15px;
    height: 100%;
}

/* Lean Tos */
#lean_tos_section {
    margin-top: 50px;
}

#lean_tos_2 {
    background-image: url(../images/lean-tos-2.webp),url(../imagesSlow/lean-tos-2.png) ;
    background-position: center;
    background-repeat: no-repeat;
    height: calc(100% - 10px);
    margin-bottom: 10px;
    background-size: cover;
}

#lean_tos_3 {
    background-image: url(../images/lean-tos-3.webp),url(../imagesSlow/lean-tos-3.png);
    background-position: center bottom;
    background-repeat: no-repeat;
    height: calc(100% - 10px);
    margin-top: 10px;
    background-size: cover;
}

#lean_tos_section .desc h2 {
    color: #3694F4;
    margin-top: 30px;
    font-weight: bold;
}

#lean_tos_section .desc p {
    font-size: 20px;
    padding: 0 20px;
}

.btn-style-1 {
    position: relative;
    padding: 10px 30px 10px 20px;
    font-size: 14px;
    font-weight: bold;
    color: black;
    background: #009688;
    border: none;
}

.btn-style-1 i {
    position: absolute;
    top: 11px;
    right: 12px;
    font-size: 16px;
}

#lean_tos_section img, 
#lean_tos_section #lean_tos_2,
#lean_tos_section #lean_tos_3,
#lean_tos_section .desc button {
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
}

#lean_tos_section .desc button a {
    color: white;
}

#lean_tos_section .mobile-view img {
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
}

@media (max-width: 767px) {
    #lean_tos_section .desc p {
        font-size: 14px;
    }

    .btn-style-1 {
        font-size: 10px;
    }

    .btn-style-1 i {
        top: 9px;
    }
}

/* Steel Buildings */
#steel_buildings_section {
    position: relative;
}

#steel_buildings_section .content button img {
    width: 10px;
    margin-right: 10px;
    margin-bottom: 2px;
}

#steel_buildings_section .content {
    position: absolute;
    bottom: 100px;
    left: calc(50% - 172px);
}

#steel_buildings_section .content h2 {
    color: #3694f4;
    font-weight: bold;
}

#steel_buildings_section .content p {
    color: black;
    font-weight: bold;
}

#steel_buildings_section .content button {
    color: black;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    font-size: 12px;
}

@media (max-width: 991px) {
    #steel_buildings_section .content {
        bottom: 70px;
    }
}

@media (max-width: 767px) {
    #steel_buildings_section .content {
        bottom: 20px;
        left: calc(50% - 150px);
    }

    #steel_buildings_section .content h2 {
        font-size: 20px;
        margin-bottom: 0;
    }

    #steel_buildings_section .content p {
        font-size: 14px;
        margin-bottom: 7px;
    }

    #steel_buildings_section .content button {
        padding: 5px 10px;
        font-size: 10px;
    }
}

/* Extra Wide Structures */
#extra_wide_structures {
    position: relative;
}

#extra_wide_structures .content {
    color: #060607;
    position: absolute;
    bottom: 20px;
    left: 20px;
}

#extra_wide_structures .bg {
    background-color: rgba(0, 0, 0, 0.2);
    height: 100%;
    width: calc(100% - 30px);
    position: absolute;
    top: 0;
}

#extra_wide_structures .content button {
    background: transparent;
    color: rgb(15, 13, 13);
    border: none;
    font-size: 24px;
    position: relative;
    padding-left: 15px;
    
    
}

#extra_wide_structures .content button i {
    font-size: 24px;
    position: absolute;
    top: 3px;
    left: 0px;
}

#extra_wide_structures .content button a {
    color: #3694f4;
    font-size: 24px;
}

#extra_wide_structures .content p {
    margin-bottom: 0;
    line-height: 1;
}

#extra_wide_structures .content h4 {
    font-weight: bold;
    margin-bottom: 0;
}

@media (max-width: 767px) {
    #extra_wide_structures .content {
        bottom: 5px;
    }

    #extra_wide_structures .content h4 {
        font-size: 16px;
    }

    #extra_wide_structures .content p {
        font-size: 10px;
    }
}

/* RV Covers */
.gallery .item {
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
    height: 100%;
    padding-bottom: 50px;
}

.gallery .item .content {
    padding: 10px;
}

.gallery .item .content h4 {
    color: #3694F4;
}

.gallery .item .content button {
    position: absolute;
    right: 30px;
    bottom: 20px;
}

#rv_covers_section button.btn-style-1 a {
    color: white;
}

@media (max-width: 767px) {
    #rv_covers_section .content p {
        font-size: 14px;
    }

    #rv_covers_section .col-md-6 {
        margin-bottom: 15px;
    }
}

/* Customize */
#customize_section p {
    line-height: 1.2;
    margin-bottom: 0;
}

#customize_section button {
    position: relative;
    padding: 0;
}

#customize_section button i {
    position: absolute;
    top: 2px;
    font-size: 12px;
}

#customize_section .right {
    padding-top: 90px;
}

#customize_section ul.alice-carousel__dots {
    position: absolute;
    bottom: 10px;
    left: calc(50% - 27px);
}

#customize_section .alice-carousel__dots-item:hover, 
#customize_section .alice-carousel__dots-item.__active {
    background-color: #e0e4fb;
}

#customize_section .alice-carousel__dots-item {
    background-color: transparent;
    border: solid 1px #e0e4fb;
}

@media (max-width: 767px) {
    #customize_section button {
        font-size: 8px;
        padding: 0;
    }

    #customize_section p {
        font-size: 14px;
    }

    #customize_section button i {
        top: -1px;
    }
}

/* Watch Video */
#watch_video_section {
    position: relative;
}

#watch_video_section .content {
    position: absolute;
    bottom: 20px;
    left: 50px;
    max-width: 370px;
    color: #3694F4;
    font-size: 24px;
}

#watch_video_section .content button {
    color: black;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    font-size: 18px;
}

#watch_video_section i {
    position: absolute;
    top: 1px;
    left: 0;
    font-size: 30px;
}

#watch_video_section h4 {
    padding-left: 40px;
    font-size: 30px;
    margin-bottom: 0;
}

.content button a {
    color: rgb(15, 13, 13);
   
}

@media (max-width: 767px) {
    #watch_video_section {
        padding: 0;
    }

    #watch_video_section h4 {
        font-size: 16px;
        padding-left: 20px;
    }

    #watch_video_section i {
        font-size: 16px;
    }

    #watch_video_section .content p {
        font-size: 10px;
        margin-bottom: 5px;
    }

    #watch_video_section .content button {
        padding: 5px 10px;
        font-size: 10px;
    }

    #watch_video_section .content {
        left: 20px;
        max-width: 225px;
    }
}

/* Build Online */
#build_online_section {
    position: relative;
}

#build_online_section .bg {
    background: rgb(0, 0, 0);
    border-radius: 10px;
    position: absolute;
    width: calc(100% - 30px);
    height: 100%;
    top: 50px;
}

#build_online_section .left {
    background: white;
}

#build_online_section .left,
#build_online_section .right {
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
    height: 100%;
}

#build_online_section .left {
    padding: 50px;
}

#build_online_section .left p {
    padding-left: 25px;
    position: relative;
}

#build_online_section .left i {
    color: #000000;
    font-size: 20px;
    position: absolute;
    top: 0;
    left: 0;
}

#build_online_section .right p {
    background: white;
    margin: 0;
    padding: 5px 15px;
    line-height: 1.3;
    font-weight: bold;
    color: rgb(0, 0, 0);
    position: absolute;
    bottom: 0;
    width: calc(100% - 30px);
    cursor: pointer;
}

@media (max-width: 767px) {
    #build_online_section .padding-0-50 {
        padding: 0 !important;
    }

    #build_online_section .bottom {
        background: white;
        z-index: 100;
        width: 100%;
        margin-top: 20px;
        padding: 20px 50px;
        font-size: 14px;
    }
}


/**********************************
 *      Product Lookup Tool
 **********************************/
.product-lookup-tool .item {
    padding: 15px 15px 30px;
    box-shadow: 3px 5px 20px rgba(0, 0, 0, 0.25);
    height: 100%;
    background: white;
}

.product-lookup-tool .item .title-div {
    overflow: hidden;
    max-width: 300px;
    margin: 20px auto;
    background: #CDE3F8;
    padding: 7px 20px;
    font-size: 16px;
}

.product-search button.search-btn {
    background: #3694F4;
    border: 0;
    color: black;
    padding: 3px 10px;
}

.product-lookup-tool .modal-body p.no-product-msg {
    background: white;
    padding: 20px;
}

/**********************************
 *      Installation Manual
 **********************************/
#installation_maual canvas {
    margin: auto;
    max-width: 100%;
}

#installation_maual .react-pdf__Document {
    margin: 30px 0;
}

#installation_maual .right-arrow {
    line-height: 24px;
    position: absolute;
    margin-right: -35px;
    cursor: pointer;
}

#installation_maual .left-arrow {
    line-height: 24px;
    position: absolute;
    margin-left: -30px;
    cursor: pointer;
}


/**********************************
 *      Product Loading Spinner
 **********************************/
.loading-spinner button {
    background: transparent;
    border: none;
    color: green;
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 1000;
}

.loading-spinner button i {
    font-size: 24px;
}

/********************************************* 
 *            Online Build Modal
 *********************************************/
p.invalid {
    margin-bottom: 7px;
    margin-top: -5px;
    font-size: 12px;
    color: red;
}

input.invalid {
    border: solid 1px red !important;
}

/********************************************* 
 *            about us page 
 *********************************************/

 #aboutTop {
     width: 100%;
     height: 400px;
     background-color: green;
     margin-top: -50px;
     position: relative;
 }

 #aboutTop .first {
    left: 0;
    height:100%;
    width: 33.33%; 
    position: absolute;
    background-image: url(../images/TNT1.jpg), url(../imagesSlow/TNT1slow.jpg);
    background-position: center;
 }

 .vertical-center {
        bottom: 50%;
        position: absolute;
        margin:0 auto;
        left:40%;
        
      }
   
  


 #aboutTop .second {
    left: 33.33%;
    height:100%;
    width: 33.33%; 
    position: absolute;
    background-image: url(../images/TNT2.jpg), url(../imagesSlow/TNT1slow.jpg);
    background-position: center;
 }


 #aboutTop .third {
    left: 66.66%;
    height:100%;
    width: 33.33%; 
    position: absolute;
    background-image: url(../images/TNT3.jpg), url(../imagesSlow/TNT1slow.jpg);
    background-position: center;
 }

 @media (max-width: 767px){

    #aboutTop{
        height: 600px;
    }
    
    #aboutTop .first{
    left: 0;
    top: 0;
    width: 100%;
    height: 33.33%;        

    }

    #aboutTop .second{
        left: 0; 
        top: 33.33%;
        width: 100%; 
        height: 33.33%; 
    }


    #aboutTop .third{
        left: 0; 
        top: 66.66%;
        width: 100%; 
        height: 33.33%; 
    }


 }

 .tntabout {
    
  color: gray;
    
}

